<template lang="pug">
main
  .MapPage
    span.Logo(@click='toBack')
      .Icon: SvgComponent(name='map-back')
      span back
    //- Map(
    //-   :marker='marker',
    //-   :arrMarkers='markers',
    //-   :changeMarker='changeMarker',
    //-   :mobile='mobile',
    //-   :item='item',
    //-   :mapRef='mapRef',
    //-   :center='center || (!mobile ? [20.8, -60.86] : [20.8, -77.86])',
    //-   :firstZoom='firstZoom',
    //-   :zoomLocation='zoomLocation'
    //- )
    Map(
      ref='map',
      @changeMarker='changeMarker',
      :marker='marker',
      :arrMarkers='markers',
      :mobile='mobile',
      :item='item',
      :center='center',
      :firstZoom='firstZoom',
      :zoomLocation='zoomLocation'
    )

    .LogoText
      .UnderHeader Choose a property to see details
    .RightSide(:class='{ RightSideOpen: marker && item, RightSideClose: !marker || !item }')
      .CloseIconContainer: .CloseIcon(@click='marker = null'): SvgComponent(name='map-close')
      .Content
        a.Image(:href='link || "/"', :class='{ ImageHover: imageHover }')
          img(:src='imageUrl', :alt='(item && item.title) || ""')
        a.Name(
          :href='link || "/"',
          @mouseover='imageHover = true',
          @mouseleave='imageHover = false'
        ) {{ item && item.title }}
        .Address {{ item && item.address }}
        .IconBlock
          .Item: span
            span.Icon: SvgComponent(name='map-bed')
            | {{ item && item.beds }}
          .Item: span
            span.Icon: SvgComponent(name='map-bath')
            | {{ item && item.baths }}
        .LinkBlock
          a.Link(:href='link || "/"')
            span
              | see details
              span.Icon: SvgComponent(name='map-red')
    .RightSideMobile(v-if='this.marker')
      .RightSideMobileContainer
        a.Image(:href='link || "/"'): img(:src='imageUrl', :alt='(item && item.name) || ""')
        .Content
          .CloseIconContainer: .CloseIcon(@click='marker = null'): SvgComponent(name='map-mclose')
          a.Info(:href='link || "/"')
            .Name {{ item && item.title }}
            .Address {{ item && item.address }}
            .IconBlock
              .Item
                span
                  span.Icon: SvgComponent(name='map-mbed')
                  | {{ item && item.beds }}
              .Item
                span
                  span.Icon: SvgComponent(name='map-mbath')
                  | {{ item && item.baths }}
            .LinkBlock
              span see details
                span.Icon: SvgComponent(name='map-sred')
</template>

<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        marker: null,
        imageHover: false,
        // link: null,
        cacheImages: [],
        center: null,
        firstZoom: true,
        zoomLocation: null,
        markersHistory: [],
        markers: [],
        mobile: window.matchMedia('(max-width: 769px)').matches,
        sourcePage: null,

        // imageUrl: 'imageUrl',
        // item: {},
        title: 'title',
      };
    },
    computed: {
      item() {
        return this.marker ? this.markers.find((item) => item.link == this.marker) : null;
      },
      imageUrl() {
        return this.item
          ? this.$hostname + (this.item.image.formats.medium?.url || this.item.image.url)
          : '/';
      },
      link() {
        return `${this.$frontname}listing/${this.item?.link}`;
      },
    },
    created() {
      this.$store.commit('pageLoaded');
      const urlId = this.$route.query.id;
      const urlLocation = this.$route.query.location;

      if (urlLocation) {
        // console.log('store', this.$store.getters);
        if (this.$store.getters.locations[0]) {
          let location = this.$store.state.locations.find((item) => item.id == urlLocation);
          console.log('location', location);
          this.center = this.getLatLong(
            location.latitude,
            location.longitude,
            location.firstZoom,
            true,
          );
          this.zoomLocation = location.firstZoom;
        } else {
          this.getLocation(urlLocation).then((res) => {
            console.log('location', res);
            this.center = this.getLatLong(res.latitude, res.longitude, res.firstZoom, true);
            this.zoomLocation = res.firstZoom;
          });
        }
      }

      this.getListings().then((res) => {
        let isSold = res.find((item) => item.link == urlId);
        //console.log(isSold, 'isSold');
        this.markers = res;
        // this.marker = isSold ? urlId : null;
        this.marker = urlId;
        this.markersHistory = [urlId];
        this.sourcePage = urlId;

        res.forEach((item) => {
          this.preloadImage(item);
        });
        // Кэш картинок
        // const pictures = res.map(
        //   (item) => this.$hostname + (item.image.formats.medium?.url || item.image.url),
        // );
        // //console.log('p', pictures);
        // const cacheImages = pictures.map((picture) => {
        //   return <link key={picture} rel="preload" href={picture} as="image" />;
        // });
        // this.setState({ cacheImages });
      });

      // const { mobile } = this.state;
      if (this.mobile) {
        document.getElementsByClassName('MapPage')[0].style.height = window.innerHeight + 'px';
      }

      document.getElementsByTagName('body')[0].style.overflow = 'hidden';

      if (localStorage.getItem('marker')) {
        marker = +localStorage.getItem('marker');
        firstZoom = true;
        center = this.getLatLong(
          this.markers[+localStorage.getItem('marker') - 1].latitude,
          this.markers[+localStorage.getItem('marker') - 1].longitude,
          15,
        );
      }

      if (localStorage.getItem('link')) {
        this.link = localStorage.getItem('link');
      }
    },

    updated() {
      // if (prevState.marker && !this.marker) {
      //   this.center = this.mapRef?.current?.leafletElement.getCenter();
      // }
    },

    beforeDestroy() {
      document.getElementsByTagName('body')[0].style.overflow = 'visible';
    },

    methods: {
      preloadImage: function (item) {
        let img = new Image();

        img.onload = () => {
          //console.log('img preloaded', img.src);
        };
        //console.log(this.$hostname + (item.image.formats.medium?.url || item.image.url));
        img.src = this.$hostname + (item.image.formats.medium?.url || item.image.url);
      },

      toBack() {
        if (this.sourcePage) {
          this.$router.push({ name: 'SinglePage', params: { id: this.sourcePage } });
        } else {
          // this.$router.push({ name: 'HomePage' });
          this.$router.push({ name: 'HomePage' });
        }
      },
      async getListings() {
        try {
          const res = await axios({
            method: 'get',
            url: `${this.$hostname}/listings?latitude_null=false&longitude_null=false&sold_eq=false`,
          });
          //console.log(res);
          return res.data;
        } catch (err) {
          //console.log(err);
        }
      },
      async getLocation(id) {
        try {
          const res = await axios({
            method: 'get',
            url: `${this.$hostname}/locations/${id}`,
          });
          //console.log(res);
          return res.data;
        } catch (err) {
          //console.log(err);
        }
      },
      changeMarker(number, backHistory, par) {
        //console.log(backHistory);
        if (+number !== this.marker) {
          this.marker = number;
          (this.markersHistory = backHistory ? backHistory : [...this.markersHistory, number]),
            (this.firstZoom = true),
            (this.center = this.getLatLong(
              this.markers.find((item) => item.link === number).latitude,
              this.markers.find((item) => item.link === number).longitude,
              this.$refs.map.$children[0].mapObject.getZoom(),
            ));
        }
        //console.log('changeMarker', this.markersHistory);

        this.$router.push({ query: { id: number } });
        // this.props.history.push(`/map/?id=${number}`);
      },
      getLatLong(lat, long, currentZoom, nonShift) {
        let newLat = lat;
        let newLong = long;
        if (!this.mobile && !nonShift) {
          newLong = long + 0.01;
          if (currentZoom) {
            if (currentZoom === 4) {
              newLong = long + 20;
            }
            if (currentZoom === 5) {
              newLong = long + 10;
            }
            if (currentZoom === 6) {
              newLong = long + 5;
            }

            if (currentZoom === 7) {
              newLong = long + 2.5;
            }

            if (currentZoom === 8) {
              newLong = long + 1.1;
            }

            if (currentZoom === 9) {
              newLong = long + 0.6;
            }

            if (currentZoom === 10) {
              newLong = long + 0.3;
            }

            if (currentZoom === 11) {
              newLong = long + 0.19;
            }

            if (currentZoom === 16) {
              newLong = long + 0.002;
            }

            if (currentZoom === 17) {
              newLong = long + 0.0015;
            }

            if (currentZoom === 18) {
              newLong = long + 0.0015;
            }
          }
        }

        return [newLat, newLong];
      },
    },
    components: {
      Map: () => import('./Map.vue'),
      SvgComponent: () => import('@/components/SvgComponent'),
    },
  };
</script>

<style lang="scss">
  .MapPage * {
    box-sizing: initial;
  }
  .MapPage {
    // background: blue;
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    grid-column: left-side / right-side;

    > .Logo {
      cursor: pointer;
      position: absolute;
      top: 50px;
      left: 100px;

      z-index: 8888;
      text-decoration: none;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:active,
      &:visited,
      &:link,
      &:hover {
        text-decoration: none;
      }

      > .Icon {
        width: 30px;
        height: 20px;
        margin-right: 10px;
      }

      &:hover > .Icon {
        -webkit-animation: bounce 1s infinite;
        animation: bounce 1s infinite;
      }

      > span {
        font-family: 'Proxima Nova';
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        text-transform: lowercase;
        color: black;
      }
    }

    > .LogoText {
      display: none;
      // display: flex;
      align-items: flex-end;

      position: absolute;
      left: 100px;
      bottom: 107px;

      width: 412px;
      // height: 224px;

      z-index: 8888;

      > .Header {
        display: flex;
        flex-direction: column;

        > span {
          font-family: 'Proto Grotesk Web';
          font-weight: bold;
          font-size: 64px;
          line-height: 77px;
          color: #000000;
        }
      }

      > .UnderHeader {
        margin-top: 20px;
        font-family: 'Proxima Nova';
        font-size: 28px;
        line-height: 40px;
        color: #000000;
      }
    }

    > .RightSide {
      position: absolute;
      top: 0px;
      right: 0px;

      width: 601px;
      height: 100vh;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      border-left: 2px solid #ededed;

      background: rgba(255, 255, 255, 0.7);
      z-index: 8888;

      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;

      &.RightSideOpen {
        width: 601px;
      }

      &.RightSideClose {
        width: 0px;
      }

      > .CloseIconContainer {
        position: absolute;
        top: 30px;
        right: 30px;
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
        padding-left: 536px;

        > .CloseIcon {
          display: flex;
          align-self: flex-end;
          width: 36px;
          height: 36px;
          cursor: pointer;
        }
      }

      > .Content {
        width: 451px;

        box-sizing: border-box;
        margin: 0px 75px;

        > .Image,
        > .ImageHover {
          display: inline-flex;
          overflow: hidden;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            -webkit-transition: 1.5s ease;
            -moz-transition: 1.5s ease;
            -o-transition: 1.5s ease;
          }
        }

        &:hover > .Image:hover,
        > .ImageHover {
          // display: inline-flex;
          overflow: hidden;

          > img {
            transition: 1s;
            display: block;
            transform: scale(1.2);
          }
        }

        > .Image {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 451px;
          height: 451px;
          margin-bottom: 20px;
        }

        > .Name {
          display: inline-flex;
          box-sizing: border-box;
          font-family: 'Proxima Nova';
          font-weight: 600;
          font-size: 30px;
          line-height: 37px;
          color: #000000;

          text-decoration: none;
          color: black;

          &:active,
          &:hover,
          &:visited,
          &:link {
            text-decoration: none;
            color: black;
          }
        }

        > .Address {
          box-sizing: border-box;
          font-family: 'Proxima Nova';
          font-size: 30px;
          line-height: 37px;
          color: #000000;
        }

        > .IconBlock {
          display: flex;

          box-sizing: border-box;
          margin-top: 20px;

          > .Item {
            &:first-child {
              margin-right: 50px;
            }

            > span {
              display: inline-flex;
              justify-content: center;
              align-items: center;

              font-family: 'Proxima Nova';
              font-size: 20px;
              line-height: 24px;
              color: #000000;

              > .Icon {
                display: flex;
                align-items: center;
                width: 25px;
                height: 25px;
                margin-right: 10px;
              }
            }
          }
        }

        > .LinkBlock {
          display: flex;
          align-items: center;

          box-sizing: border-box;
          margin-top: 45px;

          > .Link {
            display: inline-flex;
            text-decoration: none;
            color: #d41e34;

            &:hover,
            &:visited,
            &:active,
            &:link {
              text-decoration: none;
              color: #d41e34;
            }

            > span {
              display: inline-flex;
              align-items: center;

              font-family: 'Proxima Nova';
              font-size: 600;
              font-size: 24px;
              line-height: 29px;
              text-transform: lowercase;
              color: #d41e34;
              cursor: pointer;

              &:hover > .Icon {
                -webkit-animation: bounce 1s infinite;
                animation: bounce 1s infinite;
              }

              > .Icon {
                width: 30px;
                height: 20px;
                margin-left: 20px;
                margin-bottom: 4px;
              }
            }
          }
        }
      }
    }

    > .RightSideMobile {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 100%;
      display: none;

      > .RightSideMobileContainer {
        width: auto;
        min-height: 184px;
        overflow: hidden;

        display: flex;
        align-items: flex-start;
        justify-content: center;

        box-sizing: border-box;
        border-bottom: 1px solid #ededed;
        background: rgba(255, 255, 255, 0.7);
        z-index: 8888;

        > .Image {
          width: 200px;
          height: 200px;
          background-size: cover;

          > img {
            width: 200px;
            height: 200px;
          }
        }

        > .Content {
          height: 100%;
          min-width: 150px;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;

          > .CloseIconContainer {
            display: flex;
            justify-content: flex-end;

            > .CloseIcon {
              width: 16px;
              height: 16px;
              margin-top: 8px;
              margin-right: 8px;
              cursor: pointer;
            }
          }

          > .Info {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding: 0px 16px;
            margin-top: 10px;
            text-decoration: none;
            color: black;

            &:active,
            &:visited,
            &:link,
            &:hover {
              text-decoration: none;
              color: black;
            }

            > .Name {
              font-family: 'Proxima Nova';
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: black;
            }

            > .Address {
              font-family: 'Proxima Nova';
              font-size: 16px;
              line-height: 19px;
              color: black;
            }

            > .IconBlock {
              display: flex;
              align-items: center;
              box-sizing: border-box;
              margin-top: 16px;

              > .Item {
                &:first-child {
                  margin-right: 20px;
                }

                > span {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;

                  font-family: 'Proxima Nova';
                  font-size: 16px;
                  line-height: 19px;
                  color: black;

                  > .Icon {
                    display: flex;
                    align-items: center;
                    margin-right: 5px;
                  }
                }
              }
            }

            > .LinkBlock {
              display: inline-flex;
              align-items: center;
              margin-top: 24px;

              > span {
                display: inline-flex;
                align-items: center;

                font-family: 'Proxima Nova';
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                text-transform: lowercase;
                color: #d41e34;
                cursor: pointer;

                &:hover > .Icon {
                  -webkit-animation: bounce 1s infinite;
                  animation: bounce 1s infinite;
                }

                > .Icon {
                  margin-left: 8px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1280px) {
    .MapPage {
      > .RightSide {
        display: none;
      }

      > .Logo {
        top: 20px;
        left: 20px;
      }

      > .LogoText {
        left: 20px;
        bottom: 0px;
        width: 300px;

        > .Header {
          font-size: 48px;
          line-height: 58px;

          > span {
            font-size: 48px;
            line-height: 58px;
          }
        }

        > .UnderHeader {
          font-size: 18px;
          line-height: 40px;
        }
      }

      > .RightSideMobile {
        display: flex;
        overflow: hidden;
        opacity: 1;
        top: 100px;

        > .RightSideMobileContainer {
          display: flex;
          justify-content: flex-start;

          // width: 90%;
          width: 510px;
          margin-left: 20px;
          height: min-content;
          // margin: 0 auto;

          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

          > .Image {
            width: 168px;
            height: 184px;
            display: flex;
            flex-shrink: 0;

            > img {
              width: 100%;
              object-fit: cover;
              background-repeat: no-repeat !important;
            }
          }

          > .Content {
            width: 65%;

            border-bottom: none;
          }
        }
      }

      > .Map {
        > .leaflet-control-container {
          display: none;
        }
      }
    }
  }

  @media all and (min-width: 768px) and (max-width: 1280px) {
    .RightSideMobileContainer {
      .Image,
      img {
        height: 255px !important;
        width: 255px !important;
      }
      .Name,
      .Address {
        font-size: 20px !important;
        line-height: 24px !important;
      }
    }
    //   .MapPage {
    //     // > .RightSide {
    //     //     display: none;
    //     // }

    //     > .Logo {
    //       top: 56px;
    //       left: 20px;
    //       > span {
    //         font-size: 20px;
    //       }
    //     }

    //     > .LogoText {
    //       left: 20px;
    //       width: 400px;

    //       > .Header {
    //         font-size: 48px;
    //         line-height: 58px;

    //         > span {
    //           font-size: 48px;
    //           line-height: 58px;
    //         }
    //       }

    //       > .UnderHeader {
    //         font-size: 18px;
    //         line-height: 40px;
    //       }
    //     }

    //     > .RightSideMobile {
    //       min-height: 200px;
    //       display: flex;
    //       display: none;
    //     }

    //     > .Map {
    //       > .leaflet-control-container {
    //         display: none;
    //       }
    //     }
    //   }
  }

  @media (max-width: 600px) {
    .RightSideMobileContainer {
      width: 90% !important;

      margin: 0 auto !important;
    }
  }
</style>
